
<template>
    <div>
        <div class="row">
            <div v-if="success" class="col-lg-12">
                <div class="alert alert-success alert-dismissible">
                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                    <h4><i class="icon fa fa-check"></i> Auto bylo úspěšně zaevidováno!</h4>
                    <router-link to="/cars">Zpět na přehled aut.</router-link>
                </div>
            </div>
            <div v-if="errors.length" class="col-lg-12">
                <div class="alert alert-danger alert-dismissible">
                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                    <h4><i class="icon fa fa-ban"></i> Auto nemohlo být evidováno!</h4>
                    <p v-for="err in errors" :key="err">{{err}}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
                <div class="box box-primary">
                    <div class="box-header with-border">
                        <h3 class="box-title">Přidat auto</h3>
                    </div>
                    <div class="box-body">
                        <div class="form-horizontal row">
                            <div class="form-group col-lg-9" :class="{'has-error': $v.formResponses.name.$error}">
                                <label for="inputName" class="control-label col-sm-2">Název</label>

                                <div class="col-sm-10">
                                    <input id="inputName" class="form-control"  v-model="$v.formResponses.name.$model" type="text">
                                    <span v-if="!$v.formResponses.name.required" class="help-block">Povinný údaj</span>
                                    <span v-if="!$v.formResponses.name.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                </div>
                            </div>
                            <div class="form-group col-lg-3">
                                <label for="inputName" class="control-label col-sm-2">Barva</label>
                                <div class="col-lg-1">

                                </div>
                                <div class="col-sm-6">
                                    <swatches :trigger-style="{ width: '35px', height: '35px', border: '2px solid #EEEFF4' }" v-model="color" colors="text-advanced" popover-to="left"></swatches>
                                </div>
                            </div>
                            <div class="form-group col-lg-9" :class="{'has-error': $v.formResponses.customerid.$error}">
                                <label for="inputName" class="control-label col-sm-2">Zákazník</label>
                                <div class="col-sm-10">
                                    <Select2 v-model="$v.formResponses.customerid.$model" :options="minCustomers" @change="getMinMnt()" id="select1"/>
                                    <span v-if="!$v.formResponses.customerid.required" class="help-block">Povinný údaj</span>
                                    <span v-if="!minCustomers.length" class="help-block">Nemáte vedené žádné zákazníky. Můžete je vytvořit <router-link :to="{ name: 'CustomerAdd' }">zde</router-link>.</span>
                                </div>
                            </div>
                            <div class="form-group col-lg-9">
                                <label for="inputName" class="control-label col-sm-2">Řidič</label>
                                <div class="col-sm-10">
                                    <Select2 :disabled="!formResponses.customerid" v-model="$v.formResponses.maintainerid.$model" :options="minMaintainers"  id="select2"/>
                                    <!-- <span v-if="!$v.formResponses.maintainerid.required" class="help-block">Povinný údaj</span> -->
                                    <span v-if="!minMaintainers.length && formResponses.customerid" class="help-block">Tento zákazník nemá evidováné žádné osoby. Můžete je vytvořit <router-link :to="{ name: 'MaintainerAdd', params: { customerid: formResponses.customerid } }">zde</router-link>.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.box-body -->
                </div>
                <div class="nav-tabs-custom">
                    <ul class="nav nav-tabs">
                        <li class="active"><a href="#detail_tab" data-toggle="tab">Bližší informace</a></li>
                        <li><a href="#comment_tab" data-toggle="tab">Komentář</a></li>
                        <li><a href="#hidden_comment_tab" data-toggle="tab">Skrytý komentář</a></li>
                    </ul>
                    <div class="tab-content">
                      <div class="tab-pane" id="comment_tab">
                        <div :class="{'form-group': true, 'has-error': $v.formResponses.comment.$error}">
                            <label for="inputComment" class="control-label">Komentář</label>

                            <div>
                                <textarea id="inputComment" v-model="$v.formResponses.comment.$model" style="resize: none;" class="form-control" rows="5" placeholder="Komentář k autu"></textarea>
                                <span v-if="!$v.formResponses.comment.maxLength" class="help-block">Může mít maximálně 1024 znaků!</span>
                            </div>
                        </div>
                      </div>
                      <!-- /.tab-pane -->
                      <div class="tab-pane" id="hidden_comment_tab">
                        <div :class="{'form-group': true, 'has-error': $v.formResponses.hidden_comment.$error}">
                            <label for="inputHiddenComment" class="control-label">Skrytý komentář</label>

                            <div>
                                <textarea id="inputHiddenComment" v-model="$v.formResponses.hidden_comment.$model" style="resize: none;" class="form-control" rows="5" placeholder="Komentář k autu, který neuvidí zákazník"></textarea>
                                <span v-if="!$v.formResponses.hidden_comment.maxLength" class="help-block">Může mít maximálně 1024 znaků!</span>
                            </div>
                        </div>
                      </div>
                      <!-- /.tab-pane -->
                      <div class="active tab-pane" id="detail_tab">
                          <div class="row">
                                <div class="form-horizontal col-lg-6">
                                    <!-- <div class="form-group">
                                        <label for="inputVinCode" class="col-sm-3 control-label">Nádrž</label>

                                        <div class="col-sm-9">
                                        <div class="input-group" style="width: 100%">
                                            <button type="button" class="btn btn-default btn-flat" style="width: 20%" :class="{'active': tankStage == 0, 'btn-primary': tankStage == 0}" @click="tankStage = 0">0</button>
                                            <button type="button" class="btn btn-default btn-flat" style="width: 20%" :class="{'active': tankStage == 1, 'btn-primary': tankStage == 1}" @click="tankStage = 1">1/4</button>
                                            <button type="button" class="btn btn-default btn-flat" style="width: 20%" :class="{'active': tankStage == 2, 'btn-primary': tankStage == 2}" @click="tankStage = 2">1/2</button>
                                            <button type="button" class="btn btn-default btn-flat" style="width: 20%" :class="{'active': tankStage == 3, 'btn-primary': tankStage == 3}" @click="tankStage = 3">3/4</button>
                                            <button type="button" class="btn btn-default btn-flat" style="width: 20%" :class="{'active': tankStage == 4, 'btn-primary': tankStage == 4}" @click="tankStage = 4">Plná</button>
                                        </div>
                                        </div>
                                    </div> -->
                                    <div :class="{'form-group': true, 'has-error': $v.formResponses.displacement.$error}">
                                        <label for="inputDisplacement" class="col-sm-3 control-label">Objem motoru [ccm]</label>

                                        <div class="col-sm-9">
                                        <div class="input-group">
                                                    <span class="input-group-btn">
                                                        <button @click="addDisplacement()" type="button" class="btn btn-default btn-flat"><i class="fa fa-plus"></i></button>
                                                    </span>
                                                    <input @focus="displacementNewVal()" id="inputDisplacement" type="number" class="form-control" v-model="$v.formResponses.displacement.$model" min="500" max="7000">
                                                    <span class="input-group-btn">
                                                        <button @click="subDisplacement()" type="button" class="btn btn-default btn-flat"><i class="fa fa-minus"></i></button>
                                                    </span>
                                                </div>
                                        <span v-if="!$v.formResponses.displacement.integer" class="help-block">Musí být celé číslo!</span>
                                        <span v-if="!$v.formResponses.displacement.between" class="help-block">Může být pouze v rozmezí od 50 cl do 7000 cl.</span>
                                        </div>
                                    </div>
                                    <div :class="{'form-group': true, 'has-error': $v.formResponses.engine_power.$error}">
                                        <label for="inputPower" class="col-sm-3 control-label">Výkon motoru [kW]</label>

                                        <div class="col-sm-9">
                                        <div class="input-group">
                                                    <span class="input-group-btn">
                                                        <button @click="addPower()" type="button" class="btn btn-default btn-flat"><i class="fa fa-plus"></i></button>
                                                    </span>
                                                    <input id="inputPower" type="number" class="form-control" v-model="$v.formResponses.engine_power.$model" min="1" max="1000">
                                                    <span class="input-group-btn">
                                                        <button @click="subPower()" type="button" class="btn btn-default btn-flat"><i class="fa fa-minus"></i></button>
                                                    </span>
                                                </div>
                                        <span v-if="!$v.formResponses.engine_power.integer" class="help-block">Musí být celé číslo!</span>
                                        <span v-if="!$v.formResponses.engine_power.between" class="help-block">Může být pouze v rozmezí od 1 kW do 1000 kW.</span>
                                        </div>
                                    </div>
                                    <div :class="{'form-group': true, 'has-error': $v.formResponses.kilometers.$error}">
                                        <label for="inputKilometers" class="col-sm-3 control-label">Najeto [km]</label>
                                        <div class="col-sm-9">
                                            <div class="input-group">
                                                    <span class="input-group-btn">
                                                        <button @click="addKilometers()" type="button" class="btn btn-default btn-flat"><i class="fa fa-plus"></i></button>
                                                    </span>
                                                    <input id="inputDisplacement" type="number" class="form-control" v-model="$v.formResponses.kilometers.$model" min="0" max="2000000">
                                                    <span class="input-group-btn">
                                                        <button @click="subKilometers()" type="button" class="btn btn-default btn-flat"><i class="fa fa-minus"></i></button>
                                                    </span>
                                            </div>
                                        <!-- <input type="number" class="form-control" id="inputKilometers" v-model="$v.formResponses.kilometers.$model"> -->
                                        <span v-if="!$v.formResponses.kilometers.integer" class="help-block">Musí být celé číslo!</span>
                                        <span v-if="!$v.formResponses.kilometers.between" class="help-block">Musí být mezi 0 a 2 000 000!</span>
                                        </div>
                                    </div>
                                    <div :class="{'form-group': true, 'has-error': $v.formResponses.mth.$error}">
                                        <label for="inputMth" class="col-sm-3 control-label">Najeto [mth]</label>
                                        <div class="col-sm-9">
                                            <div class="input-group">
                                                    <span class="input-group-btn">
                                                        <button @click="addMth()" type="button" class="btn btn-default btn-flat"><i class="fa fa-plus"></i></button>
                                                    </span>
                                                    <input id="inputDisplacement" type="number" class="form-control" v-model="$v.formResponses.mth.$model" min="0">
                                                    <span class="input-group-btn">
                                                        <button @click="subMth()" type="button" class="btn btn-default btn-flat"><i class="fa fa-minus"></i></button>
                                                    </span>
                                            </div>
                                        <span v-if="!$v.formResponses.mth.integer" class="help-block">Musí být celé číslo!</span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{'has-error': $v.formResponses.stk.$error }">
                                        <label for="inputLicencePlate" class="col-sm-3 control-label">STK</label>
                                        <div class="col-sm-9">
                                            <div class="row">
                                                <div class="col-xs-5">
                                                    <input class="form-control" type="date" v-model="$v.formResponses.stk.$model">
                                                </div>
                                            </div>
                                            <span v-if="!$v.formResponses.stk.required" class="help-block">Povinný údaj</span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="inputLicencePlate" class="col-sm-3 control-label">Rok výroby</label>

                                        <div class="col-sm-9">
                                            <div class="row">
                                                <div class="col-xs-5">
                                                    <select class="form-control" v-model="yearMade">
                                                        <option v-for="year in yearMadeOptions" :key="year" :value="year">{{year}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                        <span v-if="!$v.formResponses.licence_plate.minLength" class="help-block">Musí mít minimálně 5 znaků!</span>
                                        <span v-if="!$v.formResponses.licence_plate.maxLength" class="help-block">Může mít maximálně 8 znaků!</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-horizontal col-lg-6">
                                    <div :class="{'form-group': true, 'has-error': $v.formResponses.engine_code.$error}">
                                        <label for="inputLicencePlate" class="col-sm-3 control-label">Kód motoru</label>

                                        <div class="col-sm-9">
                                        <input type="text" class="form-control" id="inputLicencePlate" v-model="$v.formResponses.engine_code.$model">
                                        <span v-if="!$v.formResponses.engine_code.maxLength" class="help-block">Může mít maximálně 8 znaků!</span>
                                        </div>
                                    </div>
                                    <div :class="{'form-group': true, 'has-error': $v.formResponses.licence_plate.$error}">
                                        <label for="inputLicencePlate" class="col-sm-3 control-label">SPZ</label>

                                        <div class="col-sm-9">
                                        <input type="text" class="form-control" id="inputLicencePlate" v-model="$v.formResponses.licence_plate.$model">
                                        <span v-if="!$v.formResponses.licence_plate.minLength" class="help-block">Musí mít minimálně 5 znaků!</span>
                                        <span v-if="!$v.formResponses.licence_plate.maxLength" class="help-block">Může mít maximálně 8 znaků!</span>
                                        </div>
                                    </div>
                                    <div :class="{'form-group': true, 'has-error': $v.formResponses.vin_code.$error}">
                                        <label for="inputVinCode" class="col-sm-3 control-label">VIN</label>

                                        <div class="col-sm-9">
                                        <input type="text" class="form-control" id="inputVinCode" v-model="$v.formResponses.vin_code.$model">
                                        <span v-if="!$v.formResponses.vin_code.minLength || !$v.formResponses.vin_code.maxLength" class="help-block">Musí mít 17 znaků!</span>
                                        </div>
                                    </div>
                                </div>
                          </div>

                      </div>
                      <!-- /.tab-pane -->
                    </div>
                    <!-- /.tab-content -->
                </div>
                <div class="box">
                    <div class="box-footer">
                        <div  @mouseenter="$v.formResponses.$touch()">
                            <button @click="submitForm" type="button" class="btn btn-success pull-left" style="margin-right: 10px" :disabled="$v.formResponses.$invalid">Přidat</button>
                        </div>
                        <div class="checkbox pull-left">
                            <label>
                                <input v-model="dontClosePage" type="checkbox"> Budu přidávat dál
                            </label>
                        </div>
                        <button type="button" class="btn btn-default pull-right" @click="closeTab(page.id)">Zrušit</button>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import router from "../router";

const name = 'CarAdd';

// Store manipulation methods
import { mapGetters, mapActions } from "vuex";

// Import color picker module
import Swatches from 'vue-swatches';
import "vue-swatches/dist/vue-swatches.min.css";

// Slimscroll
import "jquery-slimscroll/jquery.slimscroll.min.js";

// Select2
import Select2 from '../components/pageux/Select2';

// Vuelidate
import { required, minLength, maxLength, integer, between, requiredIf } from 'vuelidate/lib/validators'

export default {
    name,
    components: {
        Swatches,
        Select2
    },
    data() {
        return {
            page: null,
            dontClosePage: false,

            errors: [],
            success: false,

            displacementTgl: false,
            yearStkOptions: [],
            monthStkOptions: [],
            yearMadeOptions: [],

            color: '#cccccc',
            tankStage: null,
            yearMade: null,
            displacement: null,
            formResponses: {
                name: '',
                licence_plate: '',
                vin_code: '',
                engine_code: '',
                engine_power: null,
                kilometers: null,
                mth: null,
                stk: null,
                displacement: null,
                customerid: null,
                maintainerid: null,
                comment: null,
                hidden_comment: null,
            },
        }
    },
    validations: {
        formResponses: {
            name: {
                required,
                maxLength: maxLength(32)
            },
            licence_plate: {
                minLength: minLength(5),
                maxLength: maxLength(8)
            },
            vin_code: {
                minLength: minLength(17),
                maxLength: maxLength(17)
            },
            engine_code: {
                maxLength: maxLength(8)
            },
            engine_power: {
                integer,
                between: between(1, 1000)
            },
            kilometers: {
                integer,
                between: between(0, 2000000)
            },
            mth: {
                           integer,
            },
            stk: {
                required: requiredIf(function() {
                    return this.formResponses.stk
                })
            },
            displacement: {
                integer,
                between: between(50, 7000)
            },
            customerid: {
                required
            },
            maintainerid: {
                required
            },
            comment: {
                maxLength: maxLength(1024)
            },
            hidden_comment: {
                maxLength: maxLength(1024)
            }
        }
    },
    computed: mapGetters(["getAllPages", 'minCustomers', 'minMaintainers']),
    methods: {
        ...mapActions(["makeActiveTab", "addTab", "removeTab", 'closeTab', "addCar", 'fetchMinCustomers', 'fetchMinMaintainers', 'addCar', 'makeCurrentCar']),
        async getMinMnt(){
            const res = await this.fetchMinMaintainers(this.formResponses.customerid);
            if (res.mnt){
                this.formResponses.maintainerid = this.minMaintainers[0].id;
            }
        },
        getDate(t){
            const dt = new Date(t*1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            return `${day}. ${mth}. ${yrs}`;
        },
        async submitForm(e) {
            e.preventDefault();

            if (this.$v.formResponses.$invalid)
                return 0;

            this.errors = [];

            let values = {
                name: this.formResponses.name,
                licence_plate: this.formResponses.licence_plate,
                vin_code: this.formResponses.vin_code,
                engine_code: this.formResponses.engine_code,
                engine_power: this.formResponses.engine_power,
                color: this.color,
                customerid: parseInt(this.formResponses.customerid),
                maintainerid: parseInt(this.formResponses.maintainerid),
                comment: this.formResponses.comment,
                hidden_comment: this.formResponses.hidden_comment,
                kilometers: parseInt(this.formResponses.kilometers),
                mth: parseInt(this.formResponses.mth),
                displacement: this.formResponses.displacement,
                stk_expire: new Date(`${this.formResponses.stk}`).getTime()/1000,
                year_made: this.yearMade,
                tank_stage: this.tankStage
            };

            const res = await this.addCar(values)

            if (res.msg == "Success."){
                this.$alerts.toast('Záznam auta úspěšně vytvořen.', 'success');

                let remaining_cars = 25 - res.car_count;
                if(remaining_cars < 0) {
                    remaining_cars = 0;
                }

                if(res.car_count == 25) {
                    this.$swal({
                        title: "Upozornění",
                        text: "V bezplatné verzi vám již nezbývá žádné volné vozidlo, pro vytvoření dalšího vozidla si, prosím, zakupte předplatné.",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Předplatná",
                        cancelButtonText: "Zavřít",
                    }).then((result) => {
                        let profilePage = this.getAllPages.filter(page => page.name == 'PersonalInfo')[0]
                        this.addTab(profilePage);
                        this.makeActiveTab(profilePage);
                    });
                } else if(res.car_count >= 20) {
                    this.$swal({
                        title: "Upozornění",
                        text: "V bezplatné verzi můžete registrovat už jenom " + remaining_cars + " vozidel! Zvažte zakoupení předplatného pro neomezený počet vozidel.",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Předplatná",
                        cancelButtonText: "Zavřít",
                    }).then((result) => {
                        let profilePage = this.getAllPages.filter(page => page.name == 'PersonalInfo')[0]
                        this.addTab(profilePage);
                        this.makeActiveTab(profilePage);
                    });
                }

                if (this.dontClosePage){
                    // Clear data
                    this.formResponses.name = "";
                    this.formResponses.licence_plate = "";
                    this.formResponses.vin_code = "";
                    this.formResponses.engine_code = "";
                    this.formResponses.engine_power = null;
                    this.color = "#cccccc";
                    this.formResponses.comment = "";
                    this.formResponses.hidden_comment = "";
                    this.formResponses.displacement = null;
                    this.formResponses.kilometers = null;
                    this.formResponses.mth = null;
                    this.formResponses.stk =null

                    this.yearMade = null;
                    this.$nextTick(() => { this.$v.$reset() })
                }
                else{
                    //this.closeTab(this.page.id);
                    this.makeCurrentCar(res.id, this.page.id);
                }
            } else if (res.msg == "Maximum number of cars reached.") {
                this.$swal({
                        title: "Nelze registrovat nové vozidlo",
                        text: "Dosáhli jste limitu registrovaných vozidel v bezplatné verzi programu. Pokud chcete pokračovat v používání programu, zakupte si předplatné.",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Předplatná",
                        cancelButtonText: "Zavřít",
                    }).then((result) => {
                        let profilePage = this.getAllPages.filter(page => page.name == 'PersonalInfo')[0]
                        this.addTab(profilePage);
                        this.makeActiveTab(profilePage);
                    });
            } else {
                this.$alerts.message('Nastala chyba!', res.msg, 'error');
            }
        },
        addDisplacement(){
            if (this.formResponses.displacement == null)
                return null;
            if(this.formResponses.displacement < 7000)
                this.formResponses.displacement = parseInt(this.formResponses.displacement) + 100;
        },

        subDisplacement(){
            if (this.formResponses.displacement == null)
                return null;
            if(this.formResponses.displacement > 500)
                this.formResponses.displacement = parseInt(this.formResponses.displacement) - 100;
        },
        displacementNewVal(){
            if(!this.formResponses.displacement)
                this.formResponses.displacement = 1600;
        },

        addPower(){
            if (this.formResponses.engine_power == null)
                return null;
            if(this.formResponses.engine_power < 1000)
                this.formResponses.engine_power = parseInt(this.formResponses.engine_power) + 1;
        },

        subPower(){
            if (this.formResponses.engine_power == null)
                return null;
            if(this.formResponses.engine_power > 1)
                this.formResponses.engine_power = parseInt(this.formResponses.engine_power) - 1;
        },
        addKilometers(){
            if (this.formResponses.kilometers == null)
                return null;
            if(this.formResponses.kilometers < 1995001)
                this.formResponses.kilometers = parseInt(this.formResponses.kilometers) + 5000;
        },

        subKilometers(){
            if (this.formResponses.kilometers == null)
                return null;
            if(this.formResponses.kilometers > 5000)
                this.formResponses.kilometers = parseInt(this.formResponses.kilometers) - 5000;
        },
        addMth(){
            if (this.formResponses.mth == null )
              this.formResponses.mth = 0;
            else
                this.formResponses.mth = parseInt(this.formResponses.mth) + 1
        },
        subMth(){
            if (this.formResponses.mth == null || this.formResponses.mth < 1)
                this.formResponses.mth = 0;
            else
                this.formResponses.mth = parseInt(this.formResponses.mth) - 1;
        },

    },
    async created(){
        // Year and month options for STK and made year
        const currentYear = new Date().getFullYear();
        var i = currentYear - 5;
        for(i; i < currentYear + 6; i++){
            this.yearStkOptions.unshift(i);
        }
        i = currentYear - 70;
        for(i; i <= currentYear; i++){
            this.yearMadeOptions.unshift(i);
        }
        for(i = 1; i < 13; i++){
            this.monthStkOptions.push(i);
        }

        // Get predefined values
        this.formResponses.customerid = this.$route.params.customerid;
        this.formResponses.maintainerid = this.$route.params.maintainerid;

        // Get customer values
        this.fetchMinCustomers();

        // Get maintainer values
        if (this.formResponses.customerid)
            this.fetchMinMaintainers(this.formResponses.customerid).then(() => {
                if(!this.formResponses.maintainerid)
                this.formResponses.maintainerid = this.minMaintainers[0].id;
            });

        this.page = this.getAllPages.filter(page => page.name == name)[0]
        this.addTab(this.page);
        this.makeActiveTab(this.page);
    }
}
</script>

<style scoped>
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}
a {
    cursor: pointer;
}
</style>
